var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.showModal,
          expression: "showModal",
        },
      ],
      staticClass: "drPickWrap",
    },
    [
      _c(
        "div",
        { staticClass: "application-wrap" },
        [
          _c(
            "button",
            { staticClass: "btn-close", on: { click: _vm.closeModal } },
            [_vm._v(" 닫기 ")]
          ),
          _c("h1", [_vm._v("방문 신청")]),
          _c("div", { staticClass: "aw-tb" }, [
            _c("table", [
              _vm._m(0),
              _c("tr", [
                _c("th", [_vm._v("이름")]),
                _c("td", [
                  _c("div", { staticClass: "text-wrap" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.drugAskData.fullName,
                          expression: "drugAskData.fullName",
                        },
                      ],
                      attrs: { type: "text", disabled: "" },
                      domProps: { value: _vm.drugAskData.fullName },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.drugAskData,
                            "fullName",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
              ]),
              _c("tr", [
                _c("th", [_vm._v("휴대전화번호")]),
                _c("td", [
                  _c("div", { staticClass: "text-wrap" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.drugAskData.phoneNumber,
                          expression: "drugAskData.phoneNumber",
                        },
                      ],
                      attrs: { type: "text" },
                      domProps: { value: _vm.drugAskData.phoneNumber },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.drugAskData,
                            "phoneNumber",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
              ]),
              _c("tr", [
                _c("th", [_vm._v("근무처명")]),
                _c("td", [
                  _c("div", { staticClass: "text-wrap" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.drugAskData.hcpWorkPlace,
                          expression: "drugAskData.hcpWorkPlace",
                        },
                      ],
                      attrs: { type: "text" },
                      domProps: { value: _vm.drugAskData.hcpWorkPlace },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.drugAskData,
                            "hcpWorkPlace",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
              ]),
              _c("tr", [
                _c("th", [_vm._v("근무처 상세 주소")]),
                _c("td", [
                  _c("div", { staticClass: "text-wrap" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.drugAskData.hcpWorkAddress,
                          expression: "drugAskData.hcpWorkAddress",
                        },
                      ],
                      attrs: { type: "text" },
                      domProps: { value: _vm.drugAskData.hcpWorkAddress },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.drugAskData,
                            "hcpWorkAddress",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "agree-box" }, [
            _c("div", { staticClass: "checkbox-wrap" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.agreeAll,
                    expression: "agreeAll",
                  },
                ],
                attrs: { id: "che1", type: "checkbox" },
                domProps: {
                  checked: Array.isArray(_vm.agreeAll)
                    ? _vm._i(_vm.agreeAll, null) > -1
                    : _vm.agreeAll,
                },
                on: {
                  click: _vm.isAgreeAll,
                  change: function ($event) {
                    var $$a = _vm.agreeAll,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.agreeAll = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.agreeAll = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.agreeAll = $$c
                    }
                  },
                },
              }),
              _vm._m(1),
            ]),
            _c("dl", [
              _c("dt", [
                _c("div", { staticClass: "checkbox-wrap" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.terms1,
                        expression: "terms1",
                      },
                    ],
                    staticClass: "application-type",
                    attrs: { id: "che2", type: "checkbox", name: "agreeType" },
                    domProps: {
                      checked: Array.isArray(_vm.terms1)
                        ? _vm._i(_vm.terms1, null) > -1
                        : _vm.terms1,
                    },
                    on: {
                      change: function ($event) {
                        var $$a = _vm.terms1,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 && (_vm.terms1 = $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              (_vm.terms1 = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.terms1 = $$c
                        }
                      },
                    },
                  }),
                  _c("label", { attrs: { for: "che2" } }, [
                    _vm._v("개인정보 수집 및 이용 동의 (필수)"),
                  ]),
                ]),
              ]),
              _vm._m(2),
            ]),
            _c("dl", [
              _c("dt", [
                _c("div", { staticClass: "checkbox-wrap" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.terms2,
                        expression: "terms2",
                      },
                    ],
                    staticClass: "application-type",
                    attrs: { id: "che3", type: "checkbox", name: "agreeType" },
                    domProps: {
                      checked: Array.isArray(_vm.terms2)
                        ? _vm._i(_vm.terms2, null) > -1
                        : _vm.terms2,
                    },
                    on: {
                      change: function ($event) {
                        var $$a = _vm.terms2,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 && (_vm.terms2 = $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              (_vm.terms2 = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.terms2 = $$c
                        }
                      },
                    },
                  }),
                  _c("label", { attrs: { for: "che3" } }, [
                    _vm._v("개인정보 제 3자 이용 동의 (필수)"),
                  ]),
                ]),
              ]),
              _vm._m(3),
            ]),
          ]),
          _c("div", { staticClass: "btn-wrap" }, [
            _c(
              "button",
              { staticClass: "btn-application", on: { click: _vm.submit } },
              [_vm._v(" 신청하기 ")]
            ),
          ]),
          _c("alert-dialog", { attrs: { options: _vm.alertProps } }),
          _c("confirm-dialog", { attrs: { options: _vm.confirmProps } }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "128px" } }),
      _c("col", { staticStyle: { width: "auto" } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "che1" } }, [
      _c("strong", [_vm._v("전체 동의")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("dd", [
      _c("div", [
        _c(
          "div",
          {
            attrs: {
              id: "popupPrivacyAgree",
              title: "개인정보 제 3자 이용 동의 (필수)",
            },
          },
          [
            _c("div", { staticClass: "terms-wrap" }, [
              _c("h2", [_vm._v("개인정보 수집·이용 동의(필수)")]),
              _c("p", [
                _vm._v(
                  "(주)유비케어(이하 '회사')는 닥터인포 약품 정보 문의 서비스(이하 '서비스') 운영을 위하여 아래와 같이 개인정보를 수집·이용하고자 합니다. 내용을 자세히 읽으신 후 동의 여부를 결정하여 주시기 바랍니다."
                ),
              ]),
              _c("h3", [_vm._v("개인정보 수집·이용 내역")]),
              _c("h4", [_vm._v("[필수] 개인정보 수집 및 이용 동의")]),
              _c("table", [
                _c("colgroup", [
                  _c("col", { staticStyle: { width: "33%" } }),
                  _c("col", { staticStyle: { width: "33%" } }),
                  _c("col", { staticStyle: { width: "33%" } }),
                ]),
                _c("thead", [
                  _c("tr", [
                    _c("th", [_vm._v("항목")]),
                    _c("th", [_vm._v("수집 및 이용목적")]),
                    _c("th", [_vm._v("보유 및 이용기간")]),
                  ]),
                ]),
                _c("tbody", [
                  _c("tr", [
                    _c("td", [
                      _vm._v(
                        "이름, 휴대 전화 번호, 근무처 명, 근무처 상세 주소"
                      ),
                    ]),
                    _c("td", [
                      _vm._v("약품 정보 문의 및 요청"),
                      _c("br"),
                      _vm._v(" (방문 신청, 샘플 신청) "),
                    ]),
                    _c("td", [
                      _vm._v("회원 탈퇴 시 또는,"),
                      _c("br"),
                      _vm._v(" 제약사 계약 종료 3개월 후 삭제 "),
                    ]),
                  ]),
                  _c("tr", [
                    _c("td", [
                      _vm._v("이름, 근무처 명, 근무처 상세 주소, 이메일 주소 "),
                    ]),
                    _c("td", [
                      _vm._v("약품 정보 문의 및 요청"),
                      _c("br"),
                      _vm._v(" (이메일 답변 신청) "),
                    ]),
                    _c("td", [
                      _vm._v("회원 탈퇴 시 또는,"),
                      _c("br"),
                      _vm._v(" 제약사 계약 종료 3개월 후 삭제 "),
                    ]),
                  ]),
                ]),
              ]),
              _c("ul", { staticClass: "tip" }, [
                _c("li", [
                  _c("span", [_vm._v("※ ")]),
                  _vm._v(
                    "서비스 이용과정에서 기기정보, IP주소, 쿠키, 서비스 이용기록이 자동으로 수집될 수 있습니다."
                  ),
                ]),
                _c("li", [
                  _c("span", [_vm._v("※ ")]),
                  _vm._v(
                    "위의 개인정보 수집 및 이용에 대한 동의를 거부할 권리가 있습니다. 그러나 동의를 거부할 경우 서비스 이용에 제한을 받을 수 있습니다."
                  ),
                ]),
              ]),
              _c("p", { staticClass: "mgt30" }, [
                _vm._v(
                  " 더 자세한 내용에 대해서는 [개인정보처리방침]을 참고하시기 바랍니다. "
                ),
              ]),
              _c(
                "a",
                {
                  attrs: {
                    href: "https://fu.drinfo.co.kr/privacy",
                    target: "_blank",
                    title: "개인정보처리방침",
                  },
                },
                [_vm._v("(https://fu.drinfo.co.kr/privacy)")]
              ),
            ]),
          ]
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("dd", [
      _c(
        "div",
        {
          attrs: {
            id: "popupThirdAgree",
            title: "개인정보 제 3자 이용 동의 (필수)",
          },
        },
        [
          _c("div", { staticClass: "terms-wrap" }, [
            _c("h2", [_vm._v("개인정보 제3자 제공 동의(필수)")]),
            _c("p", [
              _vm._v(
                "(주)유비케어(이하 '회사')는 닥터인포 약품 정보 문의 서비스(이하 '서비스') 운영을 위하여 아래와 같이 개인정보를 제3자에게 제공하고자 합니다. 내용을 자세히 읽으신 후 동의 여부를 결정하여 주시기 바랍니다."
              ),
            ]),
            _c("h3", [_vm._v("개인정보 제3자 제공 내역")]),
            _c("table", [
              _c("colgroup", [
                _c("col", { staticStyle: { width: "25%" } }),
                _c("col", { staticStyle: { width: "30%" } }),
                _c("col", { staticStyle: { width: "30%" } }),
                _c("col", { staticStyle: { width: "15%" } }),
              ]),
              _c("thead", [
                _c("tr", [
                  _c("th", [_vm._v("제공받는 자")]),
                  _c("th", [_vm._v("제공 목적")]),
                  _c("th", [_vm._v("제공 항목")]),
                  _c("th", [_vm._v("보유 및 이용기간")]),
                ]),
              ]),
              _c("tbody", [
                _c("tr", [
                  _c("td", { attrs: { rowspan: "3" } }, [
                    _vm._v("닥터인포 상품 서비스 계약을 체결한 제휴 제약사"),
                    _c("br"),
                    _c("span", { staticClass: "txt-red" }, [
                      _vm._v("* 리스트 형태로 변경"),
                      _c("br"),
                      _vm._v("(제휴 제약사 업데이트 시 수시 반영 예정)"),
                    ]),
                  ]),
                  _c("td", [_vm._v(" - 약품 정보 문의 및 요청 (방문 신청) ")]),
                  _c("td", [
                    _vm._v(
                      " 이름, 휴대 전화 번호, 근무처 명, 근무처 상세 주소 "
                    ),
                  ]),
                  _c("td", { attrs: { rowspan: "3" } }, [
                    _vm._v(" 제공 목적 달성 시까지 "),
                  ]),
                ]),
                _c("tr", [
                  _c("td", [_vm._v("- 약품 정보 문의 및 요청 (샘플 신청)")]),
                  _c("td", [
                    _vm._v("이름, 휴대 전화 번호, 근무처 명, 근무처 상세 주소"),
                  ]),
                ]),
                _c("tr", [
                  _c("td", [
                    _vm._v("- 약품 정보 문의 및 요청 (이메일 답변 신청)"),
                  ]),
                  _c("td", [
                    _vm._v("이름, 근무처 명, 근무처 상세 주소, 이메일 주소"),
                  ]),
                ]),
              ]),
            ]),
            _c("ul", { staticClass: "tip" }, [
              _c("li", [
                _c("span", [_vm._v("※ ")]),
                _vm._v(
                  "위의 개인정보 제공에 대한 동의를 거부할 권리가 있습니다. 그러나 동의를 거부할 경우 서비스 이용에 제한을 받을 수 있습니다."
                ),
              ]),
            ]),
            _c("p", { staticClass: "mgt30" }, [
              _vm._v(
                " 더 자세한 내용에 대해서는 [개인정보처리방침]을 참고하시기 바랍니다. "
              ),
            ]),
            _c(
              "a",
              {
                attrs: {
                  href: "https://fu.drinfo.co.kr/privacy",
                  target: "_blank",
                  title: "개인정보처리방침",
                },
              },
              [_vm._v("(https://fu.drinfo.co.kr/privacy)")]
            ),
          ]),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }