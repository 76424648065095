var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "article",
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showDetail,
              expression: "showDetail",
            },
          ],
          staticClass: "layerCommon medical-view-type",
          staticStyle: { display: "block" },
          attrs: { id: "popupMedicalView" },
          on: {
            click: function ($event) {
              if ($event.target !== $event.currentTarget) {
                return null
              }
              return _vm.closeDrugInfoModel($event)
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "layer-medical-detail",
              on: {
                click: function ($event) {
                  if ($event.target !== $event.currentTarget) {
                    return null
                  }
                  return _vm.closeDrugInfoModel($event)
                },
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "medical-detail-wrap",
                  on: {
                    scroll: function ($event) {
                      $event.stopPropagation()
                      return _vm.scrollPopup($event)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "layer-utility" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn-close",
                        on: { click: _vm.closeDrugInfoModel },
                      },
                      [_vm._v(" close ")]
                    ),
                    _vm.drugInfo && _vm.drugInfo.isComment
                      ? _c(
                          "button",
                          {
                            staticClass: "btn-reply",
                            on: { click: _vm.scrollToElement },
                          },
                          [_vm._v(" reply ")]
                        )
                      : _vm._e(),
                    _c(
                      "button",
                      {
                        staticClass: "btn-next",
                        on: {
                          click: function ($event) {
                            return _vm.moveNext(_vm.liveInfoId)
                          },
                        },
                      },
                      [_vm._v(" next ")]
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "btn-prev",
                        on: {
                          click: function ($event) {
                            return _vm.movePrev(_vm.liveInfoId)
                          },
                        },
                      },
                      [_vm._v(" prev ")]
                    ),
                  ]),
                  _c("div", { staticClass: "md-header" }, [
                    _c("dl", [
                      _c("dt", [
                        _vm._v(
                          _vm._s(
                            _vm.drugInfo ? _vm.drugInfo.liveInfo.title : ""
                          )
                        ),
                      ]),
                      _vm.drugInfo && _vm.drugInfo.liveInfo
                        ? _c("dd", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("$dateFormatter")(
                                    _vm.drugInfo.liveInfo.startDate,
                                    "YYYY.MM.DD"
                                  )
                                ) +
                                " " +
                                _vm._s(
                                  _vm.drugInfo.liveInfo.startDate
                                    ? "(" +
                                        _vm.getDateString(
                                          _vm.drugInfo.liveInfo.startDate
                                        ) +
                                        ")"
                                    : ""
                                ) +
                                " "
                            ),
                          ])
                        : _vm._e(),
                    ]),
                    _c("div", { staticClass: "text-info" }, [
                      _c("span", { staticClass: "viewer" }, [
                        _vm._v(
                          _vm._s(
                            _vm.drugInfo ? _vm.drugInfo.liveInfo.viewCount : 0
                          )
                        ),
                      ]),
                      _vm.drugInfo && _vm.drugInfo.isComment
                        ? _c("span", { staticClass: "reply" }, [
                            _vm._v(_vm._s(_vm.commentCount)),
                          ])
                        : _vm._e(),
                    ]),
                  ]),
                  _vm.drugInfo && _vm.drugInfo.drugInfoType == 1
                    ? _c("div", { staticClass: "md-contents" }, [
                        _c("div", {
                          directives: [
                            {
                              name: "dompurify-html",
                              rawName: "v-dompurify-html",
                              value: _vm.drugInfo.liveInfo.htmlContent,
                              expression: "drugInfo.liveInfo.htmlContent",
                            },
                          ],
                          staticClass: "content",
                          on: { click: _vm.handleClick },
                        }),
                      ])
                    : _vm._e(),
                  _vm.drugInfo && _vm.drugInfo.drugInfoType == 2
                    ? _c("div", [
                        _c("div", { staticClass: "md-tab" }, [
                          _vm.drugInfo && _vm.drugInfo.tabTitle1
                            ? _c(
                                "button",
                                {
                                  class: { active: _vm.isTabActive1 },
                                  attrs: { id: "tab1" },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.drugInfoTab1()
                                    },
                                  },
                                },
                                [
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.drugInfo.tabTitle1)),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                          _vm.drugInfo && _vm.drugInfo.tabTitle2
                            ? _c(
                                "button",
                                {
                                  class: { active: _vm.isTabActive2 },
                                  attrs: { id: "tab2" },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.drugInfoTab2()
                                    },
                                  },
                                },
                                [
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.drugInfo.tabTitle2)),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                          _vm.drugInfo && _vm.drugInfo.tabTitle3
                            ? _c(
                                "button",
                                {
                                  class: { active: _vm.isTabActive3 },
                                  attrs: { id: "tab3" },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.drugInfoTab3()
                                    },
                                  },
                                },
                                [
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.drugInfo.tabTitle3)),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                        ]),
                        _vm.tabType === 1
                          ? _c("div", {
                              directives: [
                                {
                                  name: "dompurify-html",
                                  rawName: "v-dompurify-html",
                                  value: _vm.tabContent,
                                  expression: "tabContent",
                                },
                              ],
                              staticClass: "content",
                              on: { click: _vm.tabHandleClick },
                            })
                          : _vm.tabType === 2
                          ? _c("div", [
                              _c("iframe", {
                                ref: "iframe",
                                staticStyle: { height: "700px" },
                                attrs: {
                                  src: _vm.tabContent,
                                  frameborder: "0",
                                  width: "100%",
                                },
                              }),
                            ])
                          : _vm._e(),
                      ])
                    : _vm._e(),
                  _vm.medicalFixBanner &&
                  _vm.medicalFixBanner[0] &&
                  _vm.medicalFixBanner[1]
                    ? _c("div", { staticClass: "ad-fix-banner" }, [
                        _vm._m(0),
                        _c("div", { staticClass: "fix-b-div2" }, [
                          _c(
                            "div",
                            { staticClass: "banner01 medicalFixBanner" },
                            [
                              _c(
                                "a",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.$router.push({
                                        name: "app-medical-trend-main",
                                        query: {
                                          id: _vm.medicalFixBanner[0]
                                            .liveInfoId,
                                          entry: 181,
                                        },
                                      })
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    staticStyle: {
                                      width: "494px",
                                      height: "253px",
                                    },
                                    attrs: {
                                      src: _vm.medicalFixBanner[0].filePath,
                                      loading: "lazy",
                                    },
                                  }),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "banner02 medicalFixBanner" },
                            [
                              _c(
                                "a",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.$router.push({
                                        name: "app-medical-trend-main",
                                        query: {
                                          id: _vm.medicalFixBanner[1]
                                            .liveInfoId,
                                          entry: 181,
                                        },
                                      })
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    staticStyle: {
                                      width: "494px",
                                      height: "253px",
                                    },
                                    attrs: {
                                      src: _vm.medicalFixBanner[1].filePath,
                                      loading: "lazy",
                                    },
                                  }),
                                ]
                              ),
                            ]
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _c("comment-form", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.drugInfo && _vm.drugInfo.isComment,
                        expression: "drugInfo && drugInfo.isComment",
                      },
                    ],
                    ref: "commentForm",
                    attrs: { "content-type": 1 },
                  }),
                ],
                1
              ),
            ]
          ),
        ]
      ),
      _c("confirm-dialog", { attrs: { options: _vm.confirmProps } }),
      _c("alert-dialog", { attrs: { options: _vm.alertProps } }),
      _c("VisitCallPopup", {
        ref: "visitCallRef",
        attrs: {
          "show-modal": _vm.showVisitModal,
          "live-info-id": _vm.liveInfoId,
        },
        on: { close: _vm.closeVisitModal },
      }),
      _c("SampleCallPopup", {
        ref: "sampleCallRef",
        attrs: {
          "show-modal": _vm.showSampleModal,
          "live-info-id": _vm.liveInfoId,
        },
        on: { close: _vm.closeSampleModal },
      }),
      _c("EmailCallPopup", {
        ref: "emailCallRef",
        attrs: {
          "show-modal": _vm.showEmailModal,
          "live-info-id": _vm.liveInfoId,
        },
        on: { close: _vm.closeEmailModal },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "fix-b-title" }, [
      _c("span", [_vm._v("추천 컨텐츠")]),
      _vm._v("심사청구, 처방 레시피 등 궁금증을 해결하는"),
      _c("strong", { staticStyle: { margin: "2.5px 0px 0px 4px" } }, [
        _vm._v("메디컬트렌드"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }