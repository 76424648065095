<template>
  <article class="contents">
    <div class="medical-trend-wrap">
      <div class="search-wrap">
        <!-- display:flex -->
        <div class="left-col">
          <div class="select-wrap">
            <select v-model="query.searchType">
              <option
                v-for="option in codes.searchType"
                :key="option.code"
                :value="option.code"
              >
                {{ option.label }}
              </option>
            </select>
          </div>

          <div class="search-bar">
            <div class="text-wrap">
              <input
                v-model="query.keyword"
                type="text"
                placeholder="키워드를 입력하세요."
                class="search-type"
                @keyup.enter="fetchDrugInfos()"
              >
              <button class="btn-search">
                검색
              </button>
            </div>
          </div>
        </div>
        <div class="right-col">
          <div class="select-wrap">
            <select
              v-model="query.drOrderingType"
              @change="changeOrderType"
            >
              <option
                v-for="option in codes.drOrderingType"
                :key="option.code"
                :value="option.code"
              >
                {{ option.label }}
              </option>
              <option
                hidden
                value="0"
              >
                정렬
              </option>
            </select>
          </div>
        </div>
      </div>

      <div class="thumbnail-wrap">
        <div class="drugInfo-items-list">
          <div
            v-for="item in drugInfos"
            id="btnMedicalView"
            :key="item.liveInfoId"
            class="thumbnail-item"
            @click="openDrugInfoModal(item.liveInfoId, 137, item.externalLink)"
          >
            <div class="thumbnail-img">
              <img
                :src="item.filePath"
                style="width: 100%; height: 140px"
              >
            </div>
            <div class="title">
              <strong>{{ item.clientName }}</strong>
              <span class="s-txt">
                {{ item.title }}
              </span>
            </div>
            <div class="info">
              <span
                class="view"
              >조회 <em>{{ item.viewCount }}</em></span>
              <span
                v-show="item.isComment"
                class="reply"
              >댓글 <em>{{ item.commentCount }}</em></span>
            </div>
          </div>
        </div>
      </div>
      <pagination
        :page="currentPage"
        :records="totalRecord"
        :per="pageViewCount"
        @paging="pageUpdate"
      />
    </div>
    <popup-drug-Info-view
      ref="popupDrugInfoView"
      :live-info-ids="liveInfoIds"
      @fetchDrugInfos="fetchDrugInfos"
      @statUpdate="statUpdate"
    />
  </article>
</template>

<script>
import axios from '@axios'
import Pagination from '@/components/controls/Pagination.vue'
import { mapGetters } from 'vuex'
import { setStatistics, setStatisticsAwait } from '@/common/statistics/service'
import { callExternalApi } from '@/core/global/global-liveinfo'
import popupDrugInfoView from './PopupDrugInfoView.vue'

export default {
  components: {
    Pagination,
    popupDrugInfoView,
  },
  props: {},
  data() {
    return {
      drugInfos: [],
      orderType: 0, // 최신순,조회순,댓글순, 기본정렬
      query: {
        keyword: '',
        searchType: 1,
        drOrderingType: 0,
        page: 1,
      },
      codes: {
        searchType: [],
        drOrderingType: [],
      },
      currentPage: 1,
      totalRecord: 0,
      pageViewCount: 6,
      liveInfoIds: [],
      // 진입 통계 ID (ReferenceId로 사용)
      entry: null,
      entryStatsId: null,
    }
  },
  computed: {
    ...mapGetters({
      params: 'util/getParams',
      medicalDept: 'infoData/getMedicalDept',
      infoSetting: 'infoData/getInfoSetting',
    })
  },
  watch: {
    params() {
      if (this.params && this.params.route === this.$route.name) this.openDrugInfoModal(this.params.id, this.params.entry)
    },
    currentPage() {
      this.fetchDrugInfos()
    },
    infoSetting() {
      this.fetchDrugInfos()

      if (this.$route.query.id) this.openDrugInfoModal(this.$route.query.id, this.$route.query.entry)
      else if (this.params) this.openDrugInfoModal(this.params.id, this.params.entry)
    },
    // eslint-disable-next-line func-names
    '$route.query.id': function (newId) {
      this.openDrugInfoModal(newId, this.$route.query.entry)
    }
  },
  mounted() {
    this.fetchCodes()

    if (this.infoSetting) {
      this.fetchDrugInfos()

      if (this.$route.query.id) this.openDrugInfoModal(this.$route.query.id, this.$route.query.entry)
      else if (this.params) this.openDrugInfoModal(this.params.id, this.params.entry)
    }
  },
  methods: {
    fetchCodes() {
      axios
        .get('/fu/drugInfo/codes')
        .then((rs) => {
          const { searchType, drOrderingType } = rs.data
          this.codes.searchType = searchType
          this.codes.drOrderingType = drOrderingType
        })
        .catch(() => {
          this.showAlertDialog(
            '조회에 실패하였습니다.\n잠시 후 다시 시도해 주세요.'
          )
        })
    },
    fetchDrugInfos() {
      axios.post('/fu/drugInfo/list', {
        search: {
          query: this.query,
          medicalDept: this.medicalDept,
        },
        sort: {
          predicate: 'liveInfoId',
          reverse: true,
        },
        pagination: {
          number: this.currentPage,
          count: this.pageViewCount,
        },
      })
        .then((rs) => {
          const { items, totalRecord, liveInfoIds } = rs.data

          this.drugInfos = items
          this.totalRecord = totalRecord
          this.liveInfoIds = liveInfoIds
        })
        .catch(() => {
          this.showAlertDialog(
            '조회에 실패하였습니다.\n잠시 후 다시 시도해 주세요.'
          )
        })
    },
    changeOrderType() {
      this.fetchDrugInfos()
    },
    pageUpdate(page) {
      this.currentPage = page
    },
    async openDrugInfoModal(id, entry = 138, link = null) {
      const statsId = await setStatisticsAwait(entry, id, this.entryStatsId)

      if (!this.entry) this.entry = Number(entry)
      if (!this.entryStatsId) this.entryStatsId = statsId

      if (link !== null && link.length > 0) {
        callExternalApi("LinkUrl", link)
      } else {
        this.$refs.popupDrugInfoView.openDrugInfoModal(id)

        if (this.params) this.$store.commit('util/setParams', null)
      }
    },
    statUpdate(id) {
      if (this.entry) setStatistics(this.entry, id, this.entryStatsId)
    }
  },
}
</script>

<style scoped></style>
