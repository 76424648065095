<template>
  <div
    v-show="showModal"
    class="drPickWrap"
  >
    <div class="application-wrap">
      <button
        class="btn-close"
        @click="closeModal"
      >
        닫기
      </button>
      <h1>샘플 신청</h1>
      <div class="aw-tb">
        <table>
          <colgroup>
            <col style="width:128px ">
            <col style="width:auto ">
          </colgroup>
          <tr>
            <th>이름</th>
            <td>
              <div class="text-wrap">
                <input
                  v-model="drugAskData.fullName"
                  type="text"
                  disabled
                >
              </div>
            </td>
          </tr>
          <tr>
            <th>휴대전화번호</th>
            <td>
              <div class="text-wrap">
                <input
                  v-model="drugAskData.phoneNumber"
                  type="text"
                >
              </div>
            </td>
          </tr>
          <tr>
            <th>근무처명</th>
            <td>
              <div class="text-wrap">
                <input
                  v-model="drugAskData.hcpWorkPlace"
                  type="text"
                >
              </div>
            </td>
          </tr>
          <tr>
            <th>근무처 상세 주소</th>
            <td>
              <div class="text-wrap">
                <input
                  v-model="drugAskData.hcpWorkAddress"
                  type="text"
                >
              </div>
            </td>
          </tr>
          <tr>
            <th>우편 번호</th>
            <td>
              <div class="text-wrap">
                <input
                  v-model="drugAskData.zipCode"
                  type="text"
                >
              </div>
            </td>
          </tr>
        </table>
      </div>

      <div class="agree-box">
        <div class="checkbox-wrap">
          <input
            id="sampleChe1"
            v-model="agreeAll"
            type="checkbox"
            @click="isAgreeAll"
          >
          <label for="sampleChe1"><strong>전체 동의</strong></label>
        </div>
        <dl>
          <dt>
            <div class="checkbox-wrap">
              <input
                id="sampleChe2"
                v-model="terms1"
                type="checkbox"
                class="application-type"
                name="agreeType"
              >
              <label for="sampleChe2">개인정보 수집 및 이용 동의 (필수)</label>
            </div>
          </dt>
          <dd>
            <div>
              <div
                id="popupPrivacyAgree"
                title="개인정보 제 3자 이용 동의 (필수)"
              >
                <div class="terms-wrap">
                  <h2>개인정보 수집·이용 동의(필수)</h2>
                  <p>(주)유비케어(이하 '회사')는 닥터인포 약품 정보 문의 서비스(이하 '서비스') 운영을 위하여 아래와 같이 개인정보를 수집·이용하고자 합니다. 내용을 자세히 읽으신 후 동의 여부를 결정하여 주시기 바랍니다.</p>

                  <h3>개인정보 수집·이용 내역</h3>
                  <h4>[필수] 개인정보 수집 및 이용 동의</h4>
                  <table>
                    <colgroup>
                      <col style="width: 33%">
                      <col style="width: 33%">
                      <col style="width: 33%">
                    </colgroup>
                    <thead>
                      <tr>
                        <th>항목</th>
                        <th>수집 및 이용목적</th>
                        <th>보유 및 이용기간</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>이름, 휴대 전화 번호, 근무처 명, 근무처 상세 주소</td>
                        <td>약품 정보 문의 및 요청<br>
                          (방문 신청, 샘플 신청)
                        </td>
                        <td>회원 탈퇴 시 또는,<br>
                          제약사 계약 종료 3개월 후 삭제
                        </td>
                      </tr>
                      <tr>
                        <td>이름, 근무처 명, 근무처 상세 주소, 이메일 주소
                        </td>
                        <td>약품 정보 문의 및 요청<br>
                          (이메일 답변 신청)
                        </td>
                        <td>회원 탈퇴 시 또는,<br>
                          제약사 계약 종료 3개월 후 삭제
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <ul class="tip">
                    <li><span>※ </span>서비스 이용과정에서 기기정보, IP주소, 쿠키, 서비스 이용기록이 자동으로 수집될 수 있습니다.</li>
                    <li><span>※ </span>위의 개인정보 수집 및 이용에 대한 동의를 거부할 권리가 있습니다. 그러나 동의를 거부할 경우 서비스 이용에 제한을 받을 수 있습니다.</li>
                  </ul>
                  <p class="mgt30">
                    더 자세한 내용에 대해서는 [개인정보처리방침]을 참고하시기 바랍니다.
                  </p>
                  <a
                    href="https://fu.drinfo.co.kr/privacy"
                    target="_blank"
                    title="개인정보처리방침"
                  >(https://fu.drinfo.co.kr/privacy)</a>
                </div>

              </div>
            </div>
          </dd>
        </dl>
        <dl>
          <dt>
            <div class="checkbox-wrap">
              <input
                id="sampleChe3"
                v-model="terms2"
                type="checkbox"
                class="application-type"
                name="agreeType"
              >
              <label for="sampleChe3">개인정보 제 3자 이용 동의 (필수)</label>
            </div>
          </dt>
          <dd>
            <div
              id="popupThirdAgree"
              title="개인정보 제 3자 이용 동의 (필수)"
            ><div class="terms-wrap">
              <h2>개인정보 제3자 제공 동의(필수)</h2>
              <p>(주)유비케어(이하 '회사')는 닥터인포 약품 정보 문의 서비스(이하 '서비스') 운영을 위하여 아래와 같이 개인정보를 제3자에게 제공하고자 합니다. 내용을 자세히 읽으신 후 동의 여부를 결정하여 주시기 바랍니다.</p>

              <h3>개인정보 제3자 제공 내역</h3>
              <table>
                <colgroup>
                  <col style="width: 25%">
                  <col style="width: 30%">
                  <col style="width: 30%">
                  <col style="width: 15%">
                </colgroup>
                <thead>
                  <tr>
                    <th>제공받는 자</th>
                    <th>제공 목적</th>
                    <th>제공 항목</th>
                    <th>보유 및 이용기간</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td rowspan="3">닥터인포 상품 서비스 계약을 체결한 제휴 제약사<br>
                      <span class="txt-red">* 리스트 형태로 변경<br>(제휴 제약사 업데이트 시 수시 반영 예정)</span>
                    </td>
                    <td>
                      - 약품 정보 문의 및 요청 (방문 신청)
                    </td>
                    <td>
                      이름, 휴대 전화 번호, 근무처 명, 근무처 상세 주소
                    </td>
                    <td rowspan="3">
                      제공 목적 달성 시까지
                    </td>
                  </tr>
                  <tr>
                    <td>- 약품 정보 문의 및 요청 (샘플 신청)</td>
                    <td>이름, 휴대 전화 번호, 근무처 명, 근무처 상세 주소</td>
                  </tr>
                  <tr>
                    <td>- 약품 정보 문의 및 요청 (이메일 답변 신청)</td>
                    <td>이름, 근무처 명, 근무처 상세 주소, 이메일 주소</td>
                  </tr>
                </tbody>
              </table>

              <ul class="tip">
                <li><span>※ </span>위의 개인정보 제공에 대한 동의를 거부할 권리가 있습니다. 그러나 동의를 거부할 경우 서비스 이용에 제한을 받을 수 있습니다.</li>
              </ul>
              <p class="mgt30">
                더 자세한 내용에 대해서는 [개인정보처리방침]을 참고하시기 바랍니다.
              </p>
              <a
                href="https://fu.drinfo.co.kr/privacy"
                target="_blank"
                title="개인정보처리방침"
              >(https://fu.drinfo.co.kr/privacy)</a>
            </div>
            </div>
          </dd>
        </dl>
      </div>
      <div class="btn-wrap">
        <button
          class="btn-application"
          @click="submit"
        >
          신청하기
        </button>
      </div>
      <alert-dialog :options="alertProps" />
      <confirm-dialog :options="confirmProps" />
    </div>
  </div></template>

<script>
import axios from '@axios'
import { setStatistics } from '@/common/statistics/service'

export default {
  components: {

  },
  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
    liveInfoId: {
      type: Number,
      required: true
    },
  },
  data() {
    return {
      internalShowModal: this.showModal,
      terms1: false,
      terms2: false,
      agreeAll: false,

      drugAskType: 2, // 샘플신청

      drugAskData: {
        liveInfoId: 0,
        userId: null,
        memberId: null,
        drugAskType: 2,
        fullName: null,
        phoneNumber: null,
        hcpWorkPlace: null,
        hcpWorkAddress: null,
        zipCode: null,
      },
    }
  },
  watch: {
    liveInfoId(newVal) {
      this.drugAskData.liveInfoId = newVal
    }
  },
  mounted() {
    const agreeType = document.querySelectorAll('[name=agreeType]')
    const agreeAll = document.getElementById('sampleChe1')
    agreeType.forEach((item) => {
      item.addEventListener('click', () => {
        const checkedCnt = document.querySelectorAll('[name=agreeType]:checked').length
        if (checkedCnt === 2) {
          agreeAll.checked = true
        } else {
          agreeAll.checked = false
        }
      })
    })
  },
  methods: {
    closeModal() {
      this.internalShowModal = !this.internalShowModal
      this.$emit('close')
    },
    isAgreeAll() {
      this.terms1 = !this.agreeAll
      this.terms2 = !this.agreeAll
      this.agreeAll = !this.agreeAll
    },
    fetchDrugAsk() {
      axios.get(`fu/druginfo/getDrugAsk/${this.liveInfoId}/${this.drugAskType}`, {
      })
        .then(rs => {
          this.drugAskData = rs.data
          this.drugAskData.liveInfoId = this.liveInfoId
          this.drugAskData.drugAskType = 2 // 신규 조회시 샘플신청 고정
        })
        .catch(() => {
          this.showAlertDialog('유저 조회에 실패하였습니다.\n잠시 후 다시 시도해 주세요.')
        })
    },
    submit() {
      if (!this.terms1 || !this.terms2) {
        this.showAlertDialog('필수 항목은 반드시 동의하셔야 합니다.')
      } else if (!this.drugAskData.fullName) {
        this.showAlertDialog('이름을 입력하세요.')
      } else if (!this.drugAskData.phoneNumber) {
        this.showAlertDialog('전화번호를 확인하세요.')
      } else if (!this.drugAskData.hcpWorkPlace) {
        this.showAlertDialog('근무지를 입력하세요.')
      } else if (!this.drugAskData.hcpWorkAddress) {
        this.showAlertDialog('상세주소를 입력하세요.')
      } else if (!this.drugAskData.zipCode) {
        this.showAlertDialog('우편번호를 입력하세요.')
      } else if (!this.checkPhoneNumber()) {
        this.showAlertDialog('전화번호 양식을 확인하세요.')
      } else {
        this.saveSampleCall()
      }
    },
    checkPhoneNumber() {
      const phoneNumberRegex = /^\d{3,4}-\d{3,4}-\d{4}$/
      if (!this.drugAskData.phoneNumber.match(phoneNumberRegex)) {
        return false
      }
      return true
    },
    saveSampleCall() {
      this.$parent.checkedLogin()

      this.showConfirmDialog("샘플 신청을 하시겠습니까?", (result) => {
        if (result) {
          axios
            .put(`/fu/druginfo/drugAskSave`, this.drugAskData)
            .then(rs => {
              this.$parent.showAlertDialog('완료 되었습니다.')
              this.closeModal()

              this.terms1 = false
              this.terms2 = false
              this.agreeAll = false

              setStatistics(128, this.drugAskData.liveInfoId, rs.data.id)
            })
            .catch(() => {
              this.showAlertDialog(
                '잠시 후 다시 시도해 주세요.'
              )
            })
        }
      })
    }
  },
}

</script>
